import Company from "../../../api/Company";
import router from "../../../router";
import Swal from 'sweetalert2';

export const getCompanyNumber = async({commit}, payload) => {
    console.log(commit)
    return await Company.company_number(payload)
        .then((response) => {
            return response.data.data
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getCompanies = ({ commit }, payload) => {
    Company.all(payload.page, payload.search)
        .then((response) => {
            commit("SET_COMPANIES", response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const searchCompanies = async({ commit }, payload) => {
    return await Company.search(payload)
        .then((response) => {
            commit("SET_COMPANIES", response.data.data);
            return response
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getCompany = ({ commit }, payload) => {
    Company.show(payload)
        .then((response) => {
            commit("SET_COMPANY", response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const storeCompany = ({ dispatch }, payload) => {
    Company.store(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-company" });
            Swal.fire('Success', 'Data berhasil ditambahkan!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const updateCompany = ({ dispatch }, payload) => {
    // console.log(payload)
    Company.update(payload)
        .then(() => {
            dispatch("removeErrors", null, { root: true });
            router.push({ name: "admin-company" });
            Swal.fire('Success', 'Data berhasil di update!', "success");
        })
        .catch((error) => {
            // check if validation error
            if (error.response.status === 422) {
                dispatch("getErrors", error.response.data.errors, { root: true });
            }
        });
};

export const deleteCompany = async({ commit }, payload) => {
    return await Company.delete(payload)
        .then((res) => {

            commit("UNSET_COMPANY", payload);
            return res;
        })
        .catch((error) => {
            console.log(error);
        });
};