import Home from '../pages/web/HomePage.vue'
import Login from '../pages/admin/auth/Login.vue'
import AdminPage from '../pages/admin/AdminPage'
import DashboardPage from "../pages/admin/dashboard/DashboardPage"
import MainPage from "@/pages/admin/auth/MainPage";
import CompanyIndex from "@/pages/admin/company/Index";
import CompanyField from "@/pages/admin/company/Field";
import OrderIndex from "@/pages/admin/order/Index";
import OrderField from "@/pages/admin/order/Field";
import ProductItemIndex from "@/pages/admin/product-item/Index";
import AddOnIndex from "@/pages/admin/add-on/Index";
import ProductItemField from "@/pages/admin/product-item/Field";
import AddOnField from "@/pages/admin/add-on/Field";
import ExtraPackageIndex from "@/pages/admin/extra-package/Index";
import ExtraPackageField from "@/pages/admin/extra-package/Field";
import SettingBackground from "@/pages/admin/setting-background/settingLogin";
import UserPackageIndex from "@/pages/admin/user-package/Index";
import UserPackageField from "@/pages/admin/user-package/Field";
import MasterVoucherIndex from "@/pages/admin/master-voucher/Index";
import MasterVoucherField from "@/pages/admin/master-voucher/Field";
import MasterPackagesIndex from "@/pages/admin/master-package/Index";
import MasterPackagesField from "@/pages/admin/master-package/Field";
import UserStorageIndex from "@/pages/admin/user-storage/Index";
import UserStorageField from "@/pages/admin/user-storage/Field";
import ReleaseNoteIndex from "@/pages/admin/release-note/Index";
import ReleaseNoteField from "@/pages/admin/release-note/Field";
import UserIndex from "@/pages/admin/user/Index";
import UserField from "@/pages/admin/user/Field";

const routes = [{
        path: '/',
        // name: 'Home',
        redirect: { name: "admin-login" },
        component: Home
    },

    {
        path: '/admin',
        component: MainPage,
        redirect: { name: "admin-login" },
        children: [{
            path: '/admin/login',
            name: 'admin-login',
            component: Login
        }, ]
    },
    {
        path: '/admin',
        component: AdminPage,
        redirect: { name: "admin-dashboard" },
        children: [{
                path: "dashboard",
                name: "admin-dashboard",
                component: DashboardPage,
                meta: {
                    breadcrumb: 'Admin Dashboard'
                },
            },

            {
                path: "companies",
                name: "admin-company",
                component: CompanyIndex,
                meta: {
                    breadcrumb: 'Companies'
                },
            },
            {
                path: "companies/create",
                name: "admin-company-create",
                component: CompanyField,
            },
            {
                path: "companies/edit/:id",
                props: true,
                name: "admin-company-edit",
                component: CompanyField,
            },

            {
                path: "orders",
                name: "admin-order",
                component: OrderIndex,
            },
            {
                path: "orders/create",
                name: "admin-order-create",
                component: OrderField,
            },
            {
                path: "orders/edit/:id",
                props: true,
                name: "admin-order-edit",
                component: OrderField,
            },

            {
                path: "release-notes",
                name: "admin-release-note",
                component: ReleaseNoteIndex,
            },
            {
                path: "release-notes/create",
                name: "admin-release-note-create",
                component: ReleaseNoteField,
            },
            {
                path: "release-notes/edit/:id",
                props: true,
                name: "admin-release-note-edit",
                component: ReleaseNoteField,
            },

            {
                path: "product-item",
                name: "admin-product-item",
                component: ProductItemIndex,
            },
            {
                path: "product-item/create",
                name: "admin-product-item-create",
                component: ProductItemField,
            },  
            {
                path: "product-item/edit/:id",
                props: true,
                name: "admin-product-item-edit",
                component: ProductItemField,
            },

            {
                path: "add-on",
                name: "admin-add-on",
                component: AddOnIndex,
            },
            {
                path: "add-on/create",
                name: "admin-add-on-create",
                component: AddOnField,
            },  
            {
                path: "add-on/edit/:id",
                props: true,
                name: "admin-add-on-edit",
                component: AddOnField,
            },

            {
                path: "extra-package",
                name: "admin-extra-package",
                component: ExtraPackageIndex,
            },
            {
                path: "extra-package/create",
                name: "admin-extra-package-create",
                component: ExtraPackageField,
            },
            {
                path: "extra-package/edit/:id",
                props: true,
                name: "admin-extra-package-edit",
                component: ExtraPackageField,
            },

            {
                path: "user-package",
                name: "admin-user-package",
                component: UserPackageIndex,
            },
            {
                path: "user-package/create",
                name: "admin-user-package-create",
                component: UserPackageField,
            },
            {
                path: "user-package/edit/:id",
                props: true,
                name: "admin-user-package-edit",
                component: UserPackageField,
            },

            {
                path: "user-storage",
                name: "admin-user-storage",
                component: UserStorageIndex,
            },
            {
                path: "user-storage/create",
                name: "admin-user-storage-create",
                component: UserStorageField,
            },
            {
                path: "user-storage/edit/:id",
                props: true,
                name: "admin-user-storage-edit",
                component: UserStorageField,
            },

            {
                path: "users",
                name: "admin-user",
                component: UserIndex,
            },
            {
                path: "users/create",
                name: "admin-user-create",
                component: UserField,
            },
            {
                path: "users/edit/:id",
                props: true,
                name: "admin-user-edit",
                component: UserField,
            },
            {
                path: "setting-background",
                name: "setting-background",
                component: SettingBackground,
            },

            {
                path: "master-voucher",
                name: "admin-master-voucher",
                component: MasterVoucherIndex,
            },
            {
                path: "master-voucher/create",
                name: "admin-master-voucher-create",
                component: MasterVoucherField,
            },
            {
                path: "master-voucher/edit/:id",
                props: true,
                name: "admin-master-voucher-edit",
                component: MasterVoucherField,
            },

            {
                path: "master-packages",
                name: "admin-master-packages",
                component: MasterPackagesIndex,
            },
            {
                path: "master-packages/create",
                name: "admin-master-packages-create",
                component: MasterPackagesField,
            },
            {
                path: "master-packages/edit/:id",
                props: true,
                name: "admin-master-packages-edit",
                component: MasterPackagesField,
            },
        ]
    },
]

export default routes;