import Vue from 'vue'
import Vuex from 'vuex'

// import state
import state from "./state";
import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

// modules
import auth from './modules/auth'
import company from './modules/company'
import order from './modules/order'
import refModule from './modules/ref-module'
import releaseNote from './modules/release-note'
import productItem from './modules/product-item'
import addOn from './modules/add-on'
import subModule from './modules/sub-module'
import user from './modules/user'
import extraPackage from './modules/extra-package'
import userPackage from './modules/user-package'
import masterVoucher from './modules/master-voucher'
import masterPackage from './modules/master-packages'
import userStorage from './modules/user-storage'
import group from './modules/group'
import branch from './modules/branch'
import rbacRole from './modules/rbac-role'
import role from './modules/role'
import settingBackground from './modules/setting-background'
import setting from './modules/setting'

Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        auth,
        company,
        order,
        extraPackage,
        userPackage,
        masterVoucher,
        masterPackage,
        userStorage,
        releaseNote,
        refModule,
        productItem,
        addOn,
        subModule,
        group,
        branch,
        role,
        rbacRole,
        user,
        settingBackground,
        setting
    }
})